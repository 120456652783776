import {BasicsClass} from "@/common/BasicsClass";

export default class GoodsOrderAPIClass extends BasicsClass {
    public apiUrls: any = new Map([

        ['list', {
            url: '/goodsSale/orderList',
            name: 'list',
            label: '商品点单列表'
        }],
        ['export', {
            url: '/goodsSale/exportOrderList',
            name: 'export',
            label: '导出商品点单列表'
        }],
    ])

    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public getExportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('export').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
