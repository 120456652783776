




































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import {getPageAPIs, Util} from "@/common/util";
import GoodsOrderAPIClass from "@/common/api/goodsOrder";
export const pageApiList = getPageAPIs(GoodsOrderAPIClass);
@Component({})
export default class UserList extends Vue {
  private apiClass: any = new GoodsOrderAPIClass();

  private form: any = {
    phone: '',
    order_no: '',
    time: [], // 时间选择
  };
  private scanCode: boolean = false;

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    const params = {
      name: this.form.name,
      order_no: this.form.order_no,
      start_date: this.form.time[0],
      end_date: this.form.time[1],
      page: this.tablePage.pageNo,
      limit: this.tablePage.pageSize,
    }
    this.apiClass.getList(
        {...params},
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单号",
    phone: "手机号",
    nickname: "昵称",
    desk_name: "球桌",
    info: "商品信息",
    total_price: "总价（元）",
    created_at: "提交时间",
  };
  private merchantExcelSortList: string[] = Object.keys(this.merchantExcelList2ch)

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const {time} = form;

    const params: any = form;

    if (Array.isArray(time) && time.length === 2) {
      params.start_date = this.form.time[0]
      params.end_date = this.form.time[1]
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;

    let newParams = {
      ...this.form
    }
    delete newParams.time
    console.log('导出参数', newParams)
    this.apiClass.getExportList(
        {...newParams},
        (res: any) => {
          const start_date: string = Util.dateUtil(this.form.time[0]);
          const end_date: string = Util.dateUtil(this.form.time[1]);
          const filename = `商品点单列表 ${start_date}到${end_date}.xlsx`;
          const originList = res.data.list || [];
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                const newItem = {
                  ...item
                };
                newItem.info = ''
                newItem.list.forEach((i:any,index:number)=>{
                   newItem.info  += `${index+1}.${i.name}x${i.num} ${i.actual_price}  `
                })
                return newItem;
              })
              : [];

          console.log('newList',newList)

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }


          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  /** end 导出excel */
  @Watch("scanCode")
  private changescanCode(): void {
    if (!this.scanCode) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }
}
